import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <h1>Tradebinder</h1>
          <h3>From Bulk to Binder</h3>
          <p>Under Construction.</p>
      </header>
    </div>
  );
}

export default App;
